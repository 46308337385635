import ApiService from './ApiService';
import axios from 'axios';

export const apiGetCurrentClientData = async (params) => {
    return await ApiService.fetchData({
        url: 'client/current-client-data',
        method: 'get',
        params,
    });
};

export const apiChangeClientPassword = async (data) => {
    return await ApiService.fetchData({
        url: `client/update-password/`,
        method: 'post',
        data,
    });
};

export const apiChangeClientGooglePassword = async ({idToken , newPassword }) => {
    return await ApiService.fetchData({
        url: `client/update-google-password`,
        method: 'post',
        headers:{
            idToken,
        },
        data:{
            newPassword,
        },
    });
};

export const apiGetBillingSession = async (data) => {
    return await ApiService.fetchData({
        url: `stripe/create-session?stripeCustomerId=${data.stripeCustomerId}`,
        method: 'get',
    });
};

export const apiCancelSupscription = async (data) => {
    return await ApiService.fetchData({
        url: `client/cancel-subscription`,
        method: 'post',
        data
    });
};

export const apiUpgrageClientSubscription = async (data) => {
    return await ApiService.fetchData({
        url: `stripe/create-plan-change-checkout-session`,
        method: 'post',
        data
    });
}

export const apiGetBillingHistory = async (data) => {
    return await ApiService.fetchData({
        url: `client/invoices?lastInvoiceId=${data.lastInvoiceId}&limit=5`,
        method: 'get',
        data
    });
}


export const apiUpdateClientProfile = async (data) => {
    return await ApiService.fetchData({
        url: `client/update-profile`,
        method: 'patch',
        Headers: {
            Connection: 'keep-alive',
            timeout: 240000,
        },
        data,
    });
};

export const apiIntegrateFacebook = async (data) => {
    return await ApiService.fetchData({
        url: 'client/integerate-facebook',
        method: 'post',
        data,
    });
};

export const apiChangeFacebookStatus = async (data) => {
    return await ApiService.fetchData({
        url: `client/change-facebook-status?company_id=${data.company_id}&fb_active=${data.fb_active}`,
        method: 'post',
    });
};

export const apiGetCompanyClients = async (params) => {
    return await ApiService.fetchData({
        url: 'client/get-company-clients?company_id=' + params.company_id,
        method: 'get',
    });
};

export const apiPostClientInvite = async (data) => {
    return await ApiService.fetchData({
        url: 'client/invite-client',
        method: 'post',
        data,
    });
};

export const apiUpdateClientAccess = async (data) => {
    return await ApiService.fetchData({
        url:
            'client/update-client-access?client_id=' +
            data.clientId +
            '&role=' +
            data.role,
        method: 'patch',
    });
};

export const apiDeleteMember = async (data) => {
    return await ApiService.fetchData({
        url:'client/remove-member',
        method:'patch',
        data,
    })
}

export const apiRegisterFromInvite = async (data) => {
    return await ApiService.fetchData({
        url: 'client/register-invited-client',
        method: 'post',
        data,
    });
};
